export const messages = {
    header: "TECH SKILLS",
    programmingLanguages: "Programming Languages",
    frontend: "Frontend",
    backend: "Backend",
    testing: "Testing",
    databases: "Databases",
    devops: "DevOps",
    languages: "Languages",

};
